import { addEventDelegate } from "../premmio/public/modules/eventDelegate/eventDelegate.js";
import { xhr } from "../premmio/public/modules/xhr/xhr.js";
import { renderTemplate } from "../premmio/public/template/renderTemplate.js";
import { EpisodeBox } from "../components/episodeBox/episodeBox.html.js";
import { ProductSummary } from "../premmio/public/components/product/productSummary.html.js";
import { getUpcomingEvents } from "./live.scripts.js"; // by virtue of importing it, the declaration in the other file is running

// enable the splash once the page is fully loaded
const splash = document.querySelector("#splash");

window.addEventListener("load", () => {
  splash.classList.remove("static");
});

// get latest non-patreon episode
const getLatestMainFeedEp = () => {
  const success = (request) => {
    const episode = JSON.parse(request.response),
      latestEpisode = renderTemplate(EpisodeBox(episode, true)),
      latestEpisodeContainer = document.querySelector("#latestEpisode");

    latestEpisodeContainer.appendChild(latestEpisode);

    // and set the serverData series
    App.update({ _id: "serverData", series: episode.series });
  };

  xhr({ path: "/episodes/latest", success });
};

getLatestMainFeedEp();

// patreon tier highligher
const highlightRewards = (tier) => {
  const tierValue = tier.id,
    n = tier.dataset.n;

  const patreon = document.querySelector("#patreon");
  patreon.className = tierValue + " nth-" + n;
};

addEventDelegate("click", ".tier", highlightRewards);

// fetch random fanart
const getRandomFanart = () => {
  // make an xhr request

  const success = (request) => {
    const response = JSON.parse(request.response),
      fanartBoxes = document.querySelectorAll(".randomFanart .fanart");

    for (var i = 0; i < response.length; i++) {
      const fanart = response[i],
        fanartBox = fanartBoxes[i];

      const image = document.createElement("img"),
        link = document.createElement("a");
      image.src = fanart.image;
      image.loading = "lazy";
      link.href = fanart.url + "?ref_id=15304";
      link.target = "blank";
      link.appendChild(image);
      fanartBox.appendChild(link);
    }
  };

  xhr({ path: "/print-shop/random", success });
};

getRandomFanart();

function campaignArrows(arrow) {
  const cast = document.querySelector("#cast");
  let currCampaign = parseInt(cast.className.replace("camp", "")),
    newCampaign;

  if (arrow.className === "prev") {
    if (currCampaign === 1) {
      newCampaign = 5;
    } else {
      newCampaign = currCampaign - 1;
    }
  } else {
    if (currCampaign === 5) {
      newCampaign = 1;
    } else {
      newCampaign = currCampaign + 1;
    }
  }

  cast.className = cast.className.replace(currCampaign, newCampaign);
}

addEventDelegate("click", ".campaignArrows button", campaignArrows);

const handleGroupNameClick = (group) => {
  const campaign = group.dataset.campaign;

  changeCampaign(campaign);
};

const changeCampaign = (campaign) => {
  const cast = document.querySelector("#cast");

  cast.className = campaign;
};

addEventDelegate("click", ".campaignDetails", handleGroupNameClick);
